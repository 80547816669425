.main-rotator-outher-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
            align-items: flex-end;
    height: 100%;
}

.main-rotator-container{
    border-radius: 100% 40%;
    overflow: hidden;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    margin-top: 138px;
    margin-left: -15vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: flex-end;
        -ms-flex-pack: flex-end;
            justify-content: flex-end;
}

.main-rotator-container > img{
    max-width: 120%;
}

.main-rotator-overlay{
    font-family: 'Courgette', cursive;
    font-size: 30px;
    /* background: rgb(0,152,120);
    background: linear-gradient(90deg, rgba(0,152,120,1) 0%, rgba(0,152,120,1) 8%, rgba(229,124,4,0) 50%, rgba(0,152,120,1) 92%, rgba(0,152,120,1) 100%); */
    width: 100%;
    height: 100%;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: flex-end;
        -ms-flex-pack: flex-end;
            justify-content: flex-end;
    color: white;
}

.main-rotator-poslovica{
    width: 210px;
    margin: 0 60px 320px 0;
    padding: 0;
    text-align: center;
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    /* display: block !important; */
}

.main-rotator-navigation{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-top: 20px;
    color: #E9F0D6;
    padding: 0 50px;
}

.main-rotator-navigation svg:hover{
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    cursor: pointer;
    opacity: .8;
}

.main-rotator-poslovica > p{
    margin: 0;
    line-height: 33px;
    color: #E9F0D6;
    font-size: 30px;
}

.main-rotator-poslovica-autor{
    font-size: 15px !important;
}

.fade-out-animation{
    -webkit-animation: fade-out 2000ms;
            animation: fade-out 2000ms;
}

.overlay-baner{
    opacity: 1;
}

.main-rotator-bottom-shadow-container{
    position: absolute;
    /* height: 100%; */
    -webkit-transform: translateX(14%) translateY(-50px);
        -ms-transform: translateX(14%) translateY(-50px);
            transform: translateX(14%) translateY(-50px);
    z-index: -2;
}

.poslovica-fade-out{
    opacity: 0;
    -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
            transform: translateY(10px);
}

@-webkit-keyframes fade-out{
    0%{
        opacity: 1;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes fade-out{
    0%{
        opacity: 1;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@media all and (max-width: 1350px){
    .main-rotator-bottom-shadow-container{
        -webkit-transform: translateX(14%) translateY(60px);
            -ms-transform: translateX(14%) translateY(60px);
                transform: translateX(14%) translateY(60px);
    }
    .main-rotator-container{
        width: 100%;
    }
}

@media all and (max-width: 1000px){
    .main-rotator-outher-container{
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .main-rotator-bottom-shadow-container{
        -webkit-transform: translateX(14%) translateY(20px);
            -ms-transform: translateX(14%) translateY(20px);
                transform: translateX(14%) translateY(20px);
    }
    .main-rotator-poslovica{
        margin-bottom: 200px;
    }
    .main-rotator-container{
        width: unset;
    }
}

@media all and (max-width: 767px){
    .main-rotator-navigation{
        margin-right: 42px;
        margin-bottom: 80px;
        -webkit-transition: 500ms;
        -o-transition: 500ms;
        transition: 500ms;
        /* display: flex !important; */
        width: 80vw;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0;
    }
}