.footer-features{
    height: 100px;
    width: 100%;
    overflow: hidden;
}

.footer-features-item > img{
    position: absolute;
    /* align-self: start; */
    scale: .9;
}

.footer-features-item p{
    margin: 0;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
}

.footer-features-item{
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-features{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 10px 20px;
}

.footer-features-outher-container{
    padding: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #D6DDC3;
    border-radius: 10px;
    position: relative;
    z-index: 3;
}

.main-footer{
    height: 450px;
    width: 100vw;
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    margin-top: -50px;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-shade{
    position: absolute;
    left: 0;
    height: 180vh;
    background: rgb(233,240,214);
    background: -o-linear-gradient(bottom, rgba(233,240,214,1) 0%, rgba(233,240,214,1) 60%, rgba(0,152,120,0) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(233,240,214,1)), color-stop(60%, rgba(233,240,214,1)), to(rgba(0,152,120,0)));
    background: linear-gradient(0deg, rgba(233,240,214,1) 0%, rgba(233,240,214,1) 60%, rgba(0,152,120,0) 100%);
    width: 100%;
    z-index: -3;
    -webkit-transform: translateY(-600px);
        -ms-transform: translateY(-600px);
            transform: translateY(-600px);
    margin-top: -100vh;
}

.footer-olanrei-natpis{
    position: absolute;
    z-index: 10;
    bottom: -45px;
    left: 0;
}

.footer-info{
    position: absolute;
    bottom: 50px;
    width: 550px !important;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 20;
}

.footer-info > div{
    padding-top: 15px;
}

.footer-info a{
    padding: 0 10px;
    color: #E9F0D6;
    font-size: 18px;
}

.footer-info p{
    color: #E9F0D6;
    font-size: 18px;
}

.footer-logo{
    height: 150px;
}

.no-padding{
    padding: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-info a:hover{
    text-decoration: none;
    color: white;
}

.footer-info-border{
    border-right: 1px solid #FFF;
}

.footer-outher-container{
    max-width: 1200px;
    margin: 0 auto;
    width: 85vw;
    margin-bottom: 400px;
}

.landing-about-button{
    padding: 10px 15px;
    border: none;
    color: #E9F0D6;
    border-radius: 10px;
    background: rgb(0,61,52);
    background: -o-linear-gradient(bottom, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,61,52,1)), to(rgba(0,152,120,1)));
    background: linear-gradient(0deg, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    font-family: 'Raleway', sans-serif;
    font-weight: bold; 
}

.box-shadow-footer{
    -webkit-box-shadow: 0 0 50px rgba(0, 61, 52, .4);
            box-shadow: 0 0 50px rgba(0, 61, 52, .4);}

@media all and (max-width: 600px){
    .footer-info-upper-links{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .footer-info{
        bottom: 25px;
    }

    .footer-info-upper-links .footer-info-border{
        border-right: none;
    }

    .footer-features-item p{
        font-size: 16px;
    }
}

@media all and (max-width: 750px){
    .footer-features-item{
        margin-bottom: 20px;
    }

    .footer-features{
        height: 220px;
    }
}

@media all and (max-width: 980px){
    .footer-features{
        overflow: hidden;
    }
    .footer-shade{
        height: 270vh;
        margin-top: -190vh;
    }
}