.rezerviraj-termin-button{
    padding: 10px 40px;
    background: rgb(190,105,9);
    background: -o-linear-gradient(84deg, rgba(190,105,9,1) 0%, rgba(229,124,4,1) 100%);
    background: linear-gradient(6deg, rgba(190,105,9,1) 0%, rgba(229,124,4,1) 100%);
    color: #E9F0D6;
    border-radius: 10px;
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
}

.rezerviraj-termin-button:hover{
    color: white;
    text-decoration: none;
    background: -o-linear-gradient(84deg, #BD6909 0%, #BD6909 100%);
    background: linear-gradient(6deg, #BD6909 0%, #BD6909 100%);
    background-color: #BD6909 !important;
    /* box-shadow: 0 0 15px rgba(0, 61, 52, .4);  */
}

.main-navbar-logo{
    height: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 92px;
    scale: .9;
}

.navbar{
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    margin-left: -40px !important;
}

.nav-link{
    color: #002f3b !important;
    padding: 0 15px !important;
}

.main-navbar-item{
    border-left: 1px solid rgba(0, 47, 59, .2);
    color: rgba(0, 47, 59, .2);
}

.sticky-top{
    max-width: 1200px;
    width: 100vw;
    margin: 0 auto;
    position: fixed !important;
    /* -webkit-backface-visibility: hidden; */
}

.navbar-natpis{
    z-index: -1;
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
}

.navbar-brand{
    padding: 0;
}

.navbar-background{
    background-image: url('../../images/Bckground.jpg');
    position: fixed;
    height: 136px;
    width: 100vw;
    z-index: 500;
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    top: 0;
    background-position-x: center; 
}

.navbar-collapse{
    margin-left: -1rem;
    width: 100vw;
}

.border-top-on-collapse{
    border-top: none;
}

.nav-link{
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.nav-link:hover{
    opacity: .7;
    cursor: pointer;
}

#basic-navbar-nav{
    position: unset;
}

.navbar-logo{
    -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
}

.navbar-nav{
    position: relative;
    z-index: 4;
}

.dropdown-menu{
    background-color: #003D34 !important;
    border: none;
    padding: 1rem 0 !important;
    -webkit-transform: translateY(15px);
        -ms-transform: translateY(15px);
            transform: translateY(15px);
    padding: 0;
    border-radius: 10px !important;
}

.dropdown-item{
    -webkit-transition: 500ms !important;
    -o-transition: 500ms !important;
    transition: 500ms !important;
    padding: 0.5rem 1.5rem !important;
    color: #B0D9BF !important;
    padding: 0 auto !important;
}

.dropdown-item:hover{
    color: white !important;
    background-color: #003D34 !important;
}

.navbar-collapse{
    top: 136px;
}

.navbar-natpis-container{
    height: 136px;
    top: 0;
    /* overflow: hidden; */
    position: absolute;
    width: 100%;
    z-index: -1;
    width: 100%;
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
        align-content: start;
    -webkit-box-pack: flex-end;
        -ms-flex-pack: flex-end;
            justify-content: flex-end;
}

.navbar-dropdown-trigger{
    position: absolute;
    width: 50%;
    height: 50px;
    -webkit-transform: translateY(-45px);
        -ms-transform: translateY(-45px);
            transform: translateY(-45px);
}

.box-shadow-nav{
    -webkit-box-shadow: 0 0 20px rgba(0, 61, 52, .4);
            box-shadow: 0 0 20px rgba(0, 61, 52, .4);
}

.navbar a{
    font-size: 18px !important;
}

.active{
    background-color: initial !important;
}

.dropdown-toggle::after{
    -webkit-transform: translateY(3px);
        -ms-transform: translateY(3px);
            transform: translateY(3px);
    color: rgba(0, 0, 0, .2);
}

@media all and (max-width: 980px){
    .main-navbar-item{
        border-left: none;
    }
    .nav-link{
        height: 40px;
        background-color: #BADCC4;
        width: 100vw;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    .border-top-on-collapse{
        border-top: 1px solid rgba(0, 0, 0, .1);
    }

    .nav-link:hover{
        background-color: #C2E4CC;
        cursor: pointer;
        opacity: 1;
    }

    #basic-navbar-nav{
        position: absolute;
    }
    .dropdown-menu{
        -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
                transform: translateY(0);
        border: none !important;
        border-bottom: none !important;
        border-radius: 0 !important;
        background-color: #BADCC4 !important;
        padding: 5px 0 !important;
    }
    .dropdown-item{
        color: #003D34 !important;
    }
    .dropdown-item:hover{
        background-color: #C2E4CC !important;
        color: #003D34 !important
    }
    .set-offset-y{
        -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
                transform: translateY(-10px);
    }
    .navbar-dropdown-trigger{
        display: none;
    }
    .active{
        background-color: #BADCC4 !important;
    }
    #md-display-none{
        display: none !important;
    }
}

@media all and (max-width: 580px){
    .rezerviraj-termin-button{
        padding: 10px;
    }
    .navbar{
        margin-left: -5px !important;
    }
    .rezerviraj-termin-button-dropdown{
        display: block;
        width: 100vw;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: #E9F0D6 !important;
    }
    #md-display-none{
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}