.text-img-component-image{
    max-width: 450px;
    width: 100%;
    height: unset;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 50%;
    -webkit-box-shadow: -15px 0 0 15px #009878;
            box-shadow: -15px 0 0 15px #009878;
}

.text-container-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-left: 50px !important;
}

.image-container-component{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: flex-end;
        -ms-flex-pack: flex-end;
            justify-content: flex-end;
    padding: 20px 0;
    overflow: initial;
}

.text-image-container-outher-container{
    padding: 10px 0;
    margin-bottom: 50px;
}

.container{
    margin: 0 !important;
    padding: 0 !important;
}

.text-container-component > h2{
    color: #BD6909;
    font-family: 'Courgette', cursive;
}

.text-container-component p{
    font-family: 'Raleway', sans-serif;
}

.landing-background-logo-container{
    position: absolute;
    z-index: -1;
    max-width: 1200px;
    width: 100vw;
    height: 540px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
            align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.landing-background-logo{    
    width: initial;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

@media all and (max-width: 980px){
    .text-container-component{
        -webkit-box-align: center !important;
            -ms-flex-align: center !important;
                align-items: center !important;
        margin-top: 40px;
        padding-left: 15px !important;
    }
    .text-container-component > p{
        text-align: center;
    }
    .text-container-component > h2{
        text-align: center;
    }
    .image-container-component{
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 10px;
        /* overflow-x: hidden; */
        max-width: 100vw;
        padding: 20px 0;
    }
}

@media all and (max-width: 780px){
    .landing-background-logo{
        -webkit-transform: scale(.7);
            -ms-transform: scale(.7);
                transform: scale(.7);
    }
    .text-img-component-image{
        margin-left: 35px;
        width: 80%;
    }
}

/* @media all and (max-width: 580px){
    .text-container-component{
        padding: 0 !important;
    }
} */