.blog-text-align-center-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 780px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

.blog-text-align-center-container a{
    color: #BD6909;
    font-weight: bold;
    text-decoration: underline;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.blog-text-align-center-container a:hover{
    color: #FF8800;
}

.blog-text-align-center-container h1,
.blog-text-align-center-container h2,
.blog-text-align-center-container h3,
.blog-text-align-center-container h4,
.blog-text-align-center-container h5,
.blog-text-align-center-container h6{
    font-family: 'Courgette', cursive;
    color: #BD6909;
    text-align: center;
}

.blog-text-align-center-container p{
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

@media all and (max-width: 580px){
    .blog-text-align-center-container{
        padding: 0 10px;
    }
}