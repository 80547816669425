.landing-offer-outher-container{
    margin-top: 80px;
    font-family: 'Courgette', cursive;
    color: #BD6909;
    padding: 0 22px 0 19px !important;
}

.text-align-center{
    text-align: center;
}

.landing-offer-heading{
    margin-bottom: 20px;
    /* font-family: 'Courgette', cursive; */
}

.landing-offer-headings{
    margin-bottom: 10px;
}

.landing-offer-heading-item{
    color: #003D34;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 30px;
}

.offer-glow{
    position: absolute;
    width: 600px;
    height: 300px;
    background-color: #E9F0D6;
    border-radius: 50%;
    z-index: -1;
    left: calc(50vw - 200px);
    -webkit-filter: blur(80px);
            filter: blur(80px);
    -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
            transform: translateY(-20px);
}

@media all and (max-width: 580px){
    .d-xs-none{
        display: none !important;       
    }
    .main-outher-container{
        padding: 0 5px !important;
    }
}