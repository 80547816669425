@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Raleway', sans-serif;
  background-color: #95CFB6 !important;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p,
a{
  color: #003D34;
  font-size: 20px;
}

p{
  margin-bottom: 10px;
}

h3,
h2{
  font-size: 40px !important;
  line-height: 40px !important;
  margin-bottom: 20px !important;
}

.loading-animation-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 980px){
  p{
    text-align: center;
  }
  h3{
    text-align: center;
  }
  h2{
    margin-bottom: 20px;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-rotator-outher-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}

.main-rotator-container{
    border-radius: 100% 40%;
    overflow: hidden;
    transform: translateY(0);
    margin-top: 138px;
    margin-left: -15vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.main-rotator-container > img{
    max-width: 120%;
}

.main-rotator-overlay{
    font-family: 'Courgette', cursive;
    font-size: 30px;
    /* background: rgb(0,152,120);
    background: linear-gradient(90deg, rgba(0,152,120,1) 0%, rgba(0,152,120,1) 8%, rgba(229,124,4,0) 50%, rgba(0,152,120,1) 92%, rgba(0,152,120,1) 100%); */
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
}

.main-rotator-poslovica{
    width: 210px;
    margin: 0 60px 320px 0;
    padding: 0;
    text-align: center;
    transition: 500ms;
    /* display: block !important; */
}

.main-rotator-navigation{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    color: #E9F0D6;
    padding: 0 50px;
}

.main-rotator-navigation svg:hover{
    transition: 300ms;
    cursor: pointer;
    opacity: .8;
}

.main-rotator-poslovica > p{
    margin: 0;
    line-height: 33px;
    color: #E9F0D6;
    font-size: 30px;
}

.main-rotator-poslovica-autor{
    font-size: 15px !important;
}

.fade-out-animation{
    -webkit-animation: fade-out 2000ms;
            animation: fade-out 2000ms;
}

.overlay-baner{
    opacity: 1;
}

.main-rotator-bottom-shadow-container{
    position: absolute;
    /* height: 100%; */
    transform: translateX(14%) translateY(-50px);
    z-index: -2;
}

.poslovica-fade-out{
    opacity: 0;
    transform: translateY(10px);
}

@-webkit-keyframes fade-out{
    0%{
        opacity: 1;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes fade-out{
    0%{
        opacity: 1;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@media all and (max-width: 1350px){
    .main-rotator-bottom-shadow-container{
        transform: translateX(14%) translateY(60px);
    }
    .main-rotator-container{
        width: 100%;
    }
}

@media all and (max-width: 1000px){
    .main-rotator-outher-container{
        align-items: center;
    }
    .main-rotator-bottom-shadow-container{
        transform: translateX(14%) translateY(20px);
    }
    .main-rotator-poslovica{
        margin-bottom: 200px;
    }
    .main-rotator-container{
        width: unset;
    }
}

@media all and (max-width: 767px){
    .main-rotator-navigation{
        margin-right: 42px;
        margin-bottom: 80px;
        transition: 500ms;
        /* display: flex !important; */
        width: 80vw;
        justify-content: space-between;
        padding: 0;
    }
}
.landing-more-about-me{
    margin-bottom: 0;
    justify-content: center !important;
}

.landing-more-about-me-image{
    box-shadow: none !important;
    width: auto !important;
    width: initial !important;
    height: 100% !important;
    border-radius: 0 !important;
    max-width: 110vw !important;
}

.landing-more-about-me-container{
    margin-top: 40px;
    padding: 0 40px !important;
    position: relative;
    z-index: 2;
}

.landing-more-about-me-text > p,
.landing-more-about-me-text > h3,
.landing-more-about-me-text > h2{
    text-align: center;
    width: 100%;
}

.landing-more-about-me-text{
    align-items: center !important;
    padding-left: 90px !important;
}

/* .text-container-component p{
    margin-bottom: 5px;
} */

.landing-about-button{
    margin-top: 20px;
    color: #E9F0D6 !important;
    transition: 300ms;
    text-align: center;
}

.landing-more-about-me-text a{
    padding: 10px 80px;
}

.landing-about-button:hover{
    text-decoration: none;
    color: white !important;
    background: linear-gradient(6deg, #006753 0%, #006753 100%) !important;
}

.small-text{
    font-size: 14px;
}

.landing-more-about-me-image-container-margin{
    margin-bottom: -200px;
}

@media all and (max-width: 1000px){
    .landing-more-about-me{
        justify-content: center !important;
    }
}

@media all and (max-width: 980px){
    .landing-more-about-me-text{
        padding-left: 0 !important;
    }
    .landing-more-about-me-image{
        width: 150% !important;
    }
}

@media all and (max-width: 580px){
    .landing-about-button{
        padding: 10px 50px !important;
    }
    .landing-more-about-me-container{
        padding: 0 10px !important;
    }
    .landing-more-about-me-text{
        margin-top: 150px !important; 
    }
    .landing-more-about-me-text p{
        padding: 0 10px !important;
    }
    .landing-more-about-me-image p{
        padding: 0 20px !important;
    }
}
.landing-offer-item{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    padding-left: 0 !important;
    padding-bottom: 20px;
}

.landing-offer-item-container{
    width: 100%;
    height: 500px;
    margin: 0 10px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-offer-item-title{
    color: #E9F0D6;
    bottom: 25px;
    left: 26px;
    position: absolute;
    text-align: start;
    margin-right: 20px;
    line-height: 1 !important;
    font-size: 35px !important;
}

.landing-offer-item-overlay{
    width: -webkit-fill-available;
    width: -moz-available;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    margin: 0 7px;
    border-radius: 10px;
    transform: translateX(2px);
    transition: 500ms;
}

.landing-offer-item:hover .landing-offer-item-overlay{
    opacity: 0;
}


@media all and (max-width: 500px){
    .landing-offer-item-title{
        font-size: 24px !important;
    }
    .landing-offer-item-container{
        height: 350px;
    }
}
.landing-offer-outher-container{
    margin-top: 80px;
    font-family: 'Courgette', cursive;
    color: #BD6909;
    padding: 0 22px 0 19px !important;
}

.text-align-center{
    text-align: center;
}

.landing-offer-heading{
    margin-bottom: 20px;
    /* font-family: 'Courgette', cursive; */
}

.landing-offer-headings{
    margin-bottom: 10px;
}

.landing-offer-heading-item{
    color: #003D34;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 30px;
}

.offer-glow{
    position: absolute;
    width: 600px;
    height: 300px;
    background-color: #E9F0D6;
    border-radius: 50%;
    z-index: -1;
    left: calc(50vw - 200px);
    -webkit-filter: blur(80px);
            filter: blur(80px);
    transform: translateY(-20px);
}

@media all and (max-width: 580px){
    .d-xs-none{
        display: none !important;       
    }
    .main-outher-container{
        padding: 0 5px !important;
    }
}
.text-img-component-image{
    max-width: 450px;
    width: 100%;
    height: unset;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: -15px 0 0 15px #009878;
}

.text-container-component{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-left: 50px !important;
}

.image-container-component{
    display: flex;
    align-content: center;
    justify-content: flex-end;
    padding: 20px 0;
    overflow: visible;
    overflow: initial;
}

.text-image-container-outher-container{
    padding: 10px 0;
    margin-bottom: 50px;
}

.container{
    margin: 0 !important;
    padding: 0 !important;
}

.text-container-component > h2{
    color: #BD6909;
    font-family: 'Courgette', cursive;
}

.text-container-component p{
    font-family: 'Raleway', sans-serif;
}

.landing-background-logo-container{
    position: absolute;
    z-index: -1;
    max-width: 1200px;
    width: 100vw;
    height: 540px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.landing-background-logo{    
    width: auto;    
    width: initial;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

@media all and (max-width: 980px){
    .text-container-component{
        align-items: center !important;
        margin-top: 40px;
        padding-left: 15px !important;
    }
    .text-container-component > p{
        text-align: center;
    }
    .text-container-component > h2{
        text-align: center;
    }
    .image-container-component{
        justify-content: center;
        margin-top: 10px;
        /* overflow-x: hidden; */
        max-width: 100vw;
        padding: 20px 0;
    }
}

@media all and (max-width: 780px){
    .landing-background-logo{
        transform: scale(.7);
    }
    .text-img-component-image{
        margin-left: 35px;
        width: 80%;
    }
}

/* @media all and (max-width: 580px){
    .text-container-component{
        padding: 0 !important;
    }
} */
.rezerviraj-termin-button{
    padding: 10px 40px;
    background: rgb(190,105,9);
    background: linear-gradient(6deg, rgba(190,105,9,1) 0%, rgba(229,124,4,1) 100%);
    color: #E9F0D6;
    border-radius: 10px;
    transition: 500ms;
}

.rezerviraj-termin-button:hover{
    color: white;
    text-decoration: none;
    background: linear-gradient(6deg, #BD6909 0%, #BD6909 100%);
    background-color: #BD6909 !important;
    /* box-shadow: 0 0 15px rgba(0, 61, 52, .4);  */
}

.main-navbar-logo{
    height: 110px;
    display: flex;
    align-items: center;
    height: 92px;
    scale: .9;
}

.navbar{
    transition: 500ms;
    margin-left: -40px !important;
}

.nav-link{
    color: #002f3b !important;
    padding: 0 15px !important;
}

.main-navbar-item{
    border-left: 1px solid rgba(0, 47, 59, .2);
    color: rgba(0, 47, 59, .2);
}

.sticky-top{
    max-width: 1200px;
    width: 100vw;
    margin: 0 auto;
    position: fixed !important;
    /* -webkit-backface-visibility: hidden; */
}

.navbar-natpis{
    z-index: -1;
    object-fit: scale-down !important;
}

.navbar-brand{
    padding: 0;
}

.navbar-background{
    background-image: url(/static/media/Bckground.b791298b.jpg);
    position: fixed;
    height: 136px;
    width: 100vw;
    z-index: 500;
    transition: 500ms;
    top: 0;
    background-position-x: center; 
}

.navbar-collapse{
    margin-left: -1rem;
    width: 100vw;
}

.border-top-on-collapse{
    border-top: none;
}

.nav-link{
    transition: 300ms;
}

.nav-link:hover{
    opacity: .7;
    cursor: pointer;
}

#basic-navbar-nav{
    position: unset;
}

.navbar-logo{
    transform: translateY(10px);
    transition: 500ms;
}

.navbar-nav{
    position: relative;
    z-index: 4;
}

.dropdown-menu{
    background-color: #003D34 !important;
    border: none;
    padding: 1rem 0 !important;
    transform: translateY(15px);
    padding: 0;
    border-radius: 10px !important;
}

.dropdown-item{
    transition: 500ms !important;
    padding: 0.5rem 1.5rem !important;
    color: #B0D9BF !important;
    padding: 0 auto !important;
}

.dropdown-item:hover{
    color: white !important;
    background-color: #003D34 !important;
}

.navbar-collapse{
    top: 136px;
}

.navbar-natpis-container{
    height: 136px;
    top: 0;
    /* overflow: hidden; */
    position: absolute;
    width: 100%;
    z-index: -1;
    width: 100%;
    transition: 500ms;
    display: flex;
    align-content: start;
    justify-content: flex-end;
}

.navbar-dropdown-trigger{
    position: absolute;
    width: 50%;
    height: 50px;
    transform: translateY(-45px);
}

.box-shadow-nav{
    box-shadow: 0 0 20px rgba(0, 61, 52, .4);
}

.navbar a{
    font-size: 18px !important;
}

.active{
    background-color: transparent !important;
    background-color: initial !important;
}

.dropdown-toggle::after{
    transform: translateY(3px);
    color: rgba(0, 0, 0, .2);
}

@media all and (max-width: 980px){
    .main-navbar-item{
        border-left: none;
    }
    .nav-link{
        height: 40px;
        background-color: #BADCC4;
        width: 100vw;
        display: flex !important;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    .border-top-on-collapse{
        border-top: 1px solid rgba(0, 0, 0, .1);
    }

    .nav-link:hover{
        background-color: #C2E4CC;
        cursor: pointer;
        opacity: 1;
    }

    #basic-navbar-nav{
        position: absolute;
    }
    .dropdown-menu{
        transform: translateY(0);
        border: none !important;
        border-bottom: none !important;
        border-radius: 0 !important;
        background-color: #BADCC4 !important;
        padding: 5px 0 !important;
    }
    .dropdown-item{
        color: #003D34 !important;
    }
    .dropdown-item:hover{
        background-color: #C2E4CC !important;
        color: #003D34 !important
    }
    .set-offset-y{
        transform: translateY(-10px);
    }
    .navbar-dropdown-trigger{
        display: none;
    }
    .active{
        background-color: #BADCC4 !important;
    }
    #md-display-none{
        display: none !important;
    }
}

@media all and (max-width: 580px){
    .rezerviraj-termin-button{
        padding: 10px;
    }
    .navbar{
        margin-left: -5px !important;
    }
    .rezerviraj-termin-button-dropdown{
        display: block;
        width: 100vw;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: #E9F0D6 !important;
    }
    #md-display-none{
        display: flex !important;
    }
}
.footer-features{
    height: 100px;
    width: 100%;
    overflow: hidden;
}

.footer-features-item > img{
    position: absolute;
    /* align-self: start; */
    scale: .9;
}

.footer-features-item p{
    margin: 0;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
}

.footer-features-item{
    display: -ms-grid;
    display: grid;
    align-items: center;
    justify-content: center;
}

.footer-features{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.footer-features-outher-container{
    padding: 0 !important;
    display: flex;
    justify-content: center;
    background-color: #D6DDC3;
    border-radius: 10px;
    position: relative;
    z-index: 3;
}

.main-footer{
    height: 450px;
    width: 100vw;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 0;
    margin-top: -50px;
    z-index: 2;
    display: flex;
    justify-content: center;
}

.footer-shade{
    position: absolute;
    left: 0;
    height: 180vh;
    background: rgb(233,240,214);
    background: linear-gradient(0deg, rgba(233,240,214,1) 0%, rgba(233,240,214,1) 60%, rgba(0,152,120,0) 100%);
    width: 100%;
    z-index: -3;
    transform: translateY(-600px);
    margin-top: -100vh;
}

.footer-olanrei-natpis{
    position: absolute;
    z-index: 10;
    bottom: -45px;
    left: 0;
}

.footer-info{
    position: absolute;
    bottom: 50px;
    width: 550px !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.footer-info > div{
    padding-top: 15px;
}

.footer-info a{
    padding: 0 10px;
    color: #E9F0D6;
    font-size: 18px;
}

.footer-info p{
    color: #E9F0D6;
    font-size: 18px;
}

.footer-logo{
    height: 150px;
}

.no-padding{
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-info a:hover{
    text-decoration: none;
    color: white;
}

.footer-info-border{
    border-right: 1px solid #FFF;
}

.footer-outher-container{
    max-width: 1200px;
    margin: 0 auto;
    width: 85vw;
    margin-bottom: 400px;
}

.landing-about-button{
    padding: 10px 15px;
    border: none;
    color: #E9F0D6;
    border-radius: 10px;
    background: rgb(0,61,52);
    background: linear-gradient(0deg, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    font-family: 'Raleway', sans-serif;
    font-weight: bold; 
}

.box-shadow-footer{
    box-shadow: 0 0 50px rgba(0, 61, 52, .4);}

@media all and (max-width: 600px){
    .footer-info-upper-links{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-info{
        bottom: 25px;
    }

    .footer-info-upper-links .footer-info-border{
        border-right: none;
    }

    .footer-features-item p{
        font-size: 16px;
    }
}

@media all and (max-width: 750px){
    .footer-features-item{
        margin-bottom: 20px;
    }

    .footer-features{
        height: 220px;
    }
}

@media all and (max-width: 980px){
    .footer-features{
        overflow: hidden;
    }
    .footer-shade{
        height: 270vh;
        margin-top: -190vh;
    }
}
.main-outher-container{
    max-width: 1280px;
    width: 100%;
    /* overflow-y: hidden;  */
    margin: 0 auto;
    padding: 0 40px;
    overflow-x: unset;
}

.backgroung-img{
    background-image: url(/static/media/Bckground.b791298b.jpg);
    width: 100vw;
    height: 1600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
}

.backgroung-img-container{
    position: absolute;
    z-index: -4;
    overflow: hidden;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
}

@media all and (max-width: 980px){
    .main-outher-container{
        overflow: hidden;
    }
}

.blog-baner-container{
    width: 100vw;
    height: 400px;
    margin-top: 160px;
    display: flex;
    align-content: center;
    justify-content: start;
    margin-left: calc(-50vw + 600px);
    font-family: 'Courgette', cursive;
    box-shadow: 0 20px 50px rgba(0, 61, 52, .4);
}

.blog-baner-container > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-baner-small-title{
    position: absolute;
    top: 475px;
    left: calc(50vw - 550px);
    color: #E9F0D6;
    z-index: 2;
    margin-bottom: -20px;
    line-height: 40px;
}

.blog-baner-big-title{
    position: absolute;
    top: 486px;
    left: calc(50vw - 450px);
    color: rgba(233, 240, 214, .1);
    font-size: 80px;
    z-index: 1;
    white-space: nowrap;
}

.blog-text-blocks{
    margin: 60px 0 80px 0;
}

.link-blog-container{
    height: 80vw;
    max-height: 700px;
    width: 90%;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto -40px auto;
}

.linked-blog-image-container{
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    object-fit: cover;
}

.linked-blog-image-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.linked-blog-image-container:hover{
    cursor: pointer;
}

.linked-blog-description-container{
    background: rgb(0,61,52);
    background: linear-gradient(0deg, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 550px;
    width: 60vw;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
    margin-bottom: -40px;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

.linked-blog-description-container:hover{
    background: linear-gradient(6deg, #006753 0%, #006753 100%) !important;
    cursor: pointer;
}

.linked-blog-description-container p,
.linked-blog-description-container h2{
    margin: 0 !important;
    text-align: center;
}

.linked-blog-description-container h2{
    font-family: 'Courgette', cursive;
    color: #E9F0D6;
}

.linked-blog-description-container p{
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: #B0D9BF;
}

.margin-top-blog-paragraph{
    margin-top: 20px;
}

.blog-background-logo{
    height: 100%;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    z-index: -1;
    /* margin-right: -100px; */
}

.blog-aditional-image{
    width: 100%;
    max-width: 700px;
    max-height: 500px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.blog-aditional-image-container{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-top: 10px;
}

@media all and (max-width: 1250px){
    .blog-baner-container{
        margin-left: -40px;
    }

    .blog-baner-small-title{
        left: 20px;
    }

    .blog-baner-big-title{
        left: 40px;
    }
}

@media all and (max-width: 650px){
    .linked-blog-description-container{
        width: 100vw;
    }
}

@media all and (max-width: 580px){
    .blog-baner-container{
        margin-left: -1%;
    }
    .linked-blog-description-container p{
        font-size: 12px;
    }
    .blog-baner-small-title{
        top: 460px;
    }
}
.blog-text-align-center-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 780px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

.blog-text-align-center-container a{
    color: #BD6909;
    font-weight: bold;
    text-decoration: underline;
    transition: 300ms;
}

.blog-text-align-center-container a:hover{
    color: #FF8800;
}

.blog-text-align-center-container h1,
.blog-text-align-center-container h2,
.blog-text-align-center-container h3,
.blog-text-align-center-container h4,
.blog-text-align-center-container h5,
.blog-text-align-center-container h6{
    font-family: 'Courgette', cursive;
    color: #BD6909;
    text-align: center;
}

.blog-text-align-center-container p{
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

@media all and (max-width: 580px){
    .blog-text-align-center-container{
        padding: 0 10px;
    }
}
.about-me-my-certificates-item{
    /* padding: 0 20px; */
    overflow: hidden;
    height: 100%;
    object-fit: cover;
    /* border-radius: 10px; */
    transition: 300ms;
}

.about-me-my-certificates-item:hover{
    cursor: pointer;
}

.about-me-my-certificates-item > img{
    width: 100%;
}

.about-me-my-certificates-item:hover{
    box-shadow: 0 0 30px rgba(0, 61, 52, .1);
}

.about-me-my-certificates-item-container{
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-me-my-certificates{
    margin: 0 auto !important;
    width: 100%;
    max-width: 800px;
    margin-top: -20px !important;
}

.header{
    color: #BD6909;
    font-family: 'Courgette', cursive;
    text-align: center;
    margin-bottom: 10px;
}

.margin-bottom-25{
    margin-bottom: 25px !important;
}

.modal{
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

@media all and (max-width: 980px){
    .about-me-my-certificates{
        margin-top: -40px !important;
    }
}

@media all and (max-width: 600px){
    .about-me-my-certificates-item{
        margin-bottom: 30px;
    }
}
.contact-text-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    padding: 0 20px;
}

.contact-contact-text a{
    text-decoration: underline;
    color: #BD6909 !important;
    transition: 300ms;
}

.contact-contact-text a:hover{
    color: #FF8800 !important;
}

.contact-text-container h3{
    margin-bottom: 10px;
}

.contact-phone-number-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 60px 0;
}

.contact-phone-number{
    padding: 5px 50px;
    border-radius: 10px;
    color: #FF8800;
    font-size: 30px;
    font-family: 'Raleway', sans-serif;
    background-color: #BD6909;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-phone-number p{
    margin: 0 0 0 10px;
    color: #E9F0D6;
    font-size: 30px;
    font-weight: bolder;
}

.MuiSvgIcon-root{
    fill: #F80;
}

.contact-contact-text,
.contact-contact-heading{
    padding: 0 20px;
    text-align: center;
}

/* .contact-contact-heading{
    margin-top: 80px;
} */

.contact-contact-container{
    margin-top: 40px;
    margin-bottom: 80px;
}

.contact-contact-text p{
    margin-bottom: 0;
}

.contact-location-container{
    width: 100%;
    max-width: 1000px;
    margin: 40px auto !important;
}

.location-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.location-container p,
.location-container h3{
    text-align: center;
}

.gm-fullscreen-control{
    display: none !important;
}

/* .contact-contact-text:nth-child(even){
    background-color: red;
} */
