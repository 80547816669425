.landing-more-about-me{
    margin-bottom: 0;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
}

.landing-more-about-me-image{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    width: initial !important;
    height: 100% !important;
    border-radius: 0 !important;
    max-width: 110vw !important;
}

.landing-more-about-me-container{
    margin-top: 40px;
    padding: 0 40px !important;
    position: relative;
    z-index: 2;
}

.landing-more-about-me-text > p,
.landing-more-about-me-text > h3,
.landing-more-about-me-text > h2{
    text-align: center;
    width: 100%;
}

.landing-more-about-me-text{
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    padding-left: 90px !important;
}

/* .text-container-component p{
    margin-bottom: 5px;
} */

.landing-about-button{
    margin-top: 20px;
    color: #E9F0D6 !important;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    text-align: center;
}

.landing-more-about-me-text a{
    padding: 10px 80px;
}

.landing-about-button:hover{
    text-decoration: none;
    color: white !important;
    background: -o-linear-gradient(84deg, #006753 0%, #006753 100%) !important;
    background: linear-gradient(6deg, #006753 0%, #006753 100%) !important;
}

.small-text{
    font-size: 14px;
}

.landing-more-about-me-image-container-margin{
    margin-bottom: -200px;
}

@media all and (max-width: 1000px){
    .landing-more-about-me{
        -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
                justify-content: center !important;
    }
}

@media all and (max-width: 980px){
    .landing-more-about-me-text{
        padding-left: 0 !important;
    }
    .landing-more-about-me-image{
        width: 150% !important;
    }
}

@media all and (max-width: 580px){
    .landing-about-button{
        padding: 10px 50px !important;
    }
    .landing-more-about-me-container{
        padding: 0 10px !important;
    }
    .landing-more-about-me-text{
        margin-top: 150px !important; 
    }
    .landing-more-about-me-text p{
        padding: 0 10px !important;
    }
    .landing-more-about-me-image p{
        padding: 0 20px !important;
    }
}