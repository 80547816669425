.main-outher-container{
    max-width: 1280px;
    width: 100%;
    /* overflow-y: hidden;  */
    margin: 0 auto;
    padding: 0 40px;
    overflow-x: unset;
}

.backgroung-img{
    background-image: url('../../images/Bckground.jpg');
    width: 100vw;
    height: 1600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
}

.backgroung-img-container{
    position: absolute;
    z-index: -4;
    overflow: hidden;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
}

@media all and (max-width: 980px){
    .main-outher-container{
        overflow: hidden;
    }
}
