.contact-text-container{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
    padding: 0 20px;
}

.contact-contact-text a{
    text-decoration: underline;
    color: #BD6909 !important;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.contact-contact-text a:hover{
    color: #FF8800 !important;
}

.contact-text-container h3{
    margin-bottom: 10px;
}

.contact-phone-number-container{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 40px 0 60px 0;
}

.contact-phone-number{
    padding: 5px 50px;
    border-radius: 10px;
    color: #FF8800;
    font-size: 30px;
    font-family: 'Raleway', sans-serif;
    background-color: #BD6909;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.contact-phone-number p{
    margin: 0 0 0 10px;
    color: #E9F0D6;
    font-size: 30px;
    font-weight: bolder;
}

.MuiSvgIcon-root{
    fill: #F80;
}

.contact-contact-text,
.contact-contact-heading{
    padding: 0 20px;
    text-align: center;
}

/* .contact-contact-heading{
    margin-top: 80px;
} */

.contact-contact-container{
    margin-top: 40px;
    margin-bottom: 80px;
}

.contact-contact-text p{
    margin-bottom: 0;
}

.contact-location-container{
    width: 100%;
    max-width: 1000px;
    margin: 40px auto !important;
}

.location-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.location-container p,
.location-container h3{
    text-align: center;
}

.gm-fullscreen-control{
    display: none !important;
}

/* .contact-contact-text:nth-child(even){
    background-color: red;
} */