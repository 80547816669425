.landing-offer-item{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    padding-left: 0 !important;
    padding-bottom: 20px;
}

.landing-offer-item-container{
    width: 100%;
    height: 500px;
    margin: 0 10px;
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-offer-item-title{
    color: #E9F0D6;
    bottom: 25px;
    left: 26px;
    position: absolute;
    text-align: start;
    margin-right: 20px;
    line-height: 1 !important;
    font-size: 35px !important;
}

.landing-offer-item-overlay{
    width: -webkit-fill-available;
    width: -moz-available;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2);
    margin: 0 7px;
    border-radius: 10px;
    -webkit-transform: translateX(2px);
        -ms-transform: translateX(2px);
            transform: translateX(2px);
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
}

.landing-offer-item:hover .landing-offer-item-overlay{
    opacity: 0;
}


@media all and (max-width: 500px){
    .landing-offer-item-title{
        font-size: 24px !important;
    }
    .landing-offer-item-container{
        height: 350px;
    }
}