.blog-baner-container{
    width: 100vw;
    height: 400px;
    margin-top: 160px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    margin-left: calc(-50vw + 600px);
    font-family: 'Courgette', cursive;
    -webkit-box-shadow: 0 20px 50px rgba(0, 61, 52, .4);
            box-shadow: 0 20px 50px rgba(0, 61, 52, .4);
}

.blog-baner-container > img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

.blog-baner-small-title{
    position: absolute;
    top: 475px;
    left: calc(50vw - 550px);
    color: #E9F0D6;
    z-index: 2;
    margin-bottom: -20px;
    line-height: 40px;
}

.blog-baner-big-title{
    position: absolute;
    top: 486px;
    left: calc(50vw - 450px);
    color: rgba(233, 240, 214, .1);
    font-size: 80px;
    z-index: 1;
    white-space: nowrap;
}

.blog-text-blocks{
    margin: 60px 0 80px 0;
}

.link-blog-container{
    height: 80vw;
    max-height: 700px;
    width: 90%;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto -40px auto;
}

.linked-blog-image-container{
    height: 100%;
    width: 100%;
    overflow: hidden;
    -o-object-fit: cover;
       object-fit: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    object-fit: cover;
}

.linked-blog-image-container img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

.linked-blog-image-container:hover{
    cursor: pointer;
}

.linked-blog-description-container{
    background: rgb(0,61,52);
    background: -o-linear-gradient(bottom, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,61,52,1)), to(rgba(0,152,120,1)));
    background: linear-gradient(0deg, rgba(0,61,52,1) 0%, rgba(0,152,120,1) 100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 550px;
    width: 60vw;
    padding: 10px 10px 15px 10px;
    border-radius: 10px;
    margin-bottom: -40px;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

.linked-blog-description-container:hover{
    background: -o-linear-gradient(84deg, #006753 0%, #006753 100%) !important;
    background: linear-gradient(6deg, #006753 0%, #006753 100%) !important;
    cursor: pointer;
}

.linked-blog-description-container p,
.linked-blog-description-container h2{
    margin: 0 !important;
    text-align: center;
}

.linked-blog-description-container h2{
    font-family: 'Courgette', cursive;
    color: #E9F0D6;
}

.linked-blog-description-container p{
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: #B0D9BF;
}

.margin-top-blog-paragraph{
    margin-top: 20px;
}

.blog-background-logo{
    height: 100%;
    max-width: 1200px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
            align-items: flex-start;
    -webkit-box-pack: flex-end;
        -ms-flex-pack: flex-end;
            justify-content: flex-end;
    position: absolute;
    z-index: -1;
    /* margin-right: -100px; */
}

.blog-aditional-image{
    width: 100%;
    max-width: 700px;
    max-height: 500px;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
}

.blog-aditional-image-container{
    width: 100%;
    height: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 10px;
    margin-top: 10px;
}

@media all and (max-width: 1250px){
    .blog-baner-container{
        margin-left: -40px;
    }

    .blog-baner-small-title{
        left: 20px;
    }

    .blog-baner-big-title{
        left: 40px;
    }
}

@media all and (max-width: 650px){
    .linked-blog-description-container{
        width: 100vw;
    }
}

@media all and (max-width: 580px){
    .blog-baner-container{
        margin-left: -1%;
    }
    .linked-blog-description-container p{
        font-size: 12px;
    }
    .blog-baner-small-title{
        top: 460px;
    }
}