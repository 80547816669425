.about-me-my-certificates-item{
    /* padding: 0 20px; */
    overflow: hidden;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    /* border-radius: 10px; */
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.about-me-my-certificates-item:hover{
    cursor: pointer;
}

.about-me-my-certificates-item > img{
    width: 100%;
}

.about-me-my-certificates-item:hover{
    -webkit-box-shadow: 0 0 30px rgba(49, 134, 122, 0.1);
            box-shadow: 0 0 30px rgba(0, 61, 52, .1);
}

.about-me-my-certificates-item-container{
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.about-me-my-certificates{
    margin: 0 auto !important;
    width: 100%;
    max-width: 800px;
    margin-top: -20px !important;
}

.header{
    color: #BD6909;
    font-family: 'Courgette', cursive;
    text-align: center;
    margin-bottom: 10px;
}

.margin-bottom-25{
    margin-bottom: 25px !important;
}

.modal{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.modal-body{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
}

@media all and (max-width: 980px){
    .about-me-my-certificates{
        margin-top: -40px !important;
    }
}

@media all and (max-width: 600px){
    .about-me-my-certificates-item{
        margin-bottom: 30px;
    }
}